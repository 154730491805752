import React, { Component } from "react";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { stringify } from "querystring";
import { getFirebaseData } from "../services/localApi";

class Login extends Component {
    state = {
        userName: undefined,
        password: undefined,
        loading: false,
        isError: false,
    };

    constructor(props) {
        super(props);
        if (localStorage.session) {
            this.props.history.push("/");
        }
    }

    _handleChangeName = (e) => {
        this.setState({
            userName: e.target.value,
            isError: false,
        });
    };

    _handleChangePassword = (e) => {
        if (e.key === "Enter") {
            this.loginTracking();
        }
        this.setState({
            password: e.target.value,
            isError: false,
        });
    };

    loginTracking = () => {
        this.setState({
            loading: true,
        });
        const config = {
            headers: { "Access-Control-Allow-Origin": "*" },
        };
        // axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
        // axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
        axios
            .post(
                //"http://localhost:8030/users/login",
                "https://dash.shippify.co/users/login",
                {
                    email: this.state.userName,
                    password: this.state.password,
                },
                config
            )
            .then((response) => {
                return getFirebaseData((error, data) => {
                    console.log("entra aca: ", error, data)
                    if(error){
                        localStorage.removeItem("firebase");
                        localStorage.removeItem("session");
                        localStorage.removeItem("sessionUser");
                        this.props.history.push("/");
                    }else{
                        localStorage.setItem("firebase", JSON.stringify(data));
                        localStorage.setItem("session", response.data.payload.data.apiToken);
                        localStorage.setItem("sessionUser", response.data.payload.data.user);
                        this.props.history.push("/");
                        this.setState({
                            loading: false,
                        });
                    }
                })
            })
            .catch((err) => {
                console.log("error??", err);
                console.error(err);
                this.setState({
                    loading: false,
                    isError: true,
                });
            });
    };

    render() {
        return (
            <div className="shy-container-login">
                <div className="shy-container-login-wrapper">
                    <div className="shy-login-left">
                        <img alt="Logo-shippify" className="shy-login-left-img" src="https://cdn.shippify.co/web/images/latest/logo-shippify.svg" />
                        <div className="shy-login-left-body">
                            <div className="shy-login-left-body-container">
                                <span className="shy-login-left-body-label">Correo electronico</span>
                                <input type="text" className="shy-login-left-body-input" onChange={this._handleChangeName} />
                            </div>
                            <div className="shy-login-left-body-container shy-login-left-space">
                                <span className="shy-login-left-body-label">Contraseña</span>
                                <input
                                    type="password"
                                    className="shy-login-left-body-input"
                                    onKeyPress={this._handleChangePassword}
                                    onChange={this._handleChangePassword}
                                />
                            </div>
                            <div className={this.state.isError ? "shy-login-error" : "shy-login-no-error"}>
                                <span className="text-shippify">Email o contraseña inválida.</span>
                            </div>

                            <div className="shy-login-left-button" onClick={this.loginTracking}>
                                <span className="shy-login-left-button-text">Iniciar sesión</span>
                                <ClipLoader sizeUnit={"px"} size={15} color={"#fff"} loading={this.state.loading} />
                            </div>
                        </div>
                    </div>
                    <div className="shy-login-right">
                        <img className="shy-login-right-img" src="https://cdn.shippify.co/images/img-shipper-features-3@3x.jpg" alt="shippify" />
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
