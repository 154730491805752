import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

/* Component */
import Movilbus from "./components/Movilbus";
import Login from "./components/Login";

const initRouter = (props) => (
    <div className="App">
        <Switch>
            <Route path="/login" component={Login} />
            <AuthRoute exact path="/" component={Movilbus} />
            <Redirect to="/" />
        </Switch>
    </div>
);

const AuthRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (localStorage.session ? <Component {...props} /> : <Redirect to={{ pathname: "/login" }} />)} />
);

export default initRouter;
