import React, { useState } from "react";
import "./import.scss";
import XLSX from "xlsx";
import moment from "moment";
import Dropzone from "react-dropzone";
import imgDropFile from "./../../assets/images/img-drop-file.svg";
import { getPlaces, createDeliveries, loadCompaniesFilters } from "./../../services/localApi";
import Select from "react-select";

function Import({ setErrorMessage, setLoadingDeliveries }) {
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState();
    const [date, setDate] = useState(moment().format("yyyy-MM-DD"));
    const [selectedCompany, selectCompany] = useState();
    const [companies, setCompanies] = useState();
    const [warehouse, setWarehouse] = useState();
    const [warehouses, setWarehouses] = useState([]);
    const [error, setError] = useState({ file: false, company: false, place: false });
    const [cabecera, setCabecera] = useState([
        { title: "CÓDIGO ORACLE" },
        { title: "AREA" },
        { title: "SUB AREA" },
        { title: +moment(date).format("D") },
    ]);
    const [loadingPlace, setLoadingPlace] = useState();
    const [loadingCompany, setLoadingCompany] = useState();

    const fileLoad = (file) => {
        if (file) {
            setFileName(file.name);
            try {
                let reader = new FileReader();
                reader.onload = async function(e) {
                    let data = new Uint8Array(e.target.result);
                    let workbook = XLSX.read(data, { type: "array" });
                    let worksheet = workbook.Sheets[workbook.SheetNames[0]];
                    let sheet = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                    setFile(sheet);
                };
                reader.readAsArrayBuffer(file);
            } catch (exception) {
                console.log(exception);
            }
        }
    };

    const isCorrect = () => {
        error.file = file ? false : true;
        error.company = selectedCompany ? false : true;
        error.place = warehouse ? false : true;
        setError({ ...error });
        return !error.file && !error.company && !error.place;
    };

    const parsearData = () => {
        if (!isCorrect()) {
            setErrorMessage(["Uno o más campos contienen errores. Corrígelos e intenta nuevamente."]);
            return;
        }
        let json = {
            date: date,
            companyId: selectedCompany.value,
            clientPrefix: selectedCompany.label.toUpperCase(),
            warehouseId: warehouse.value,
            clients: [],
        };
        if (file.length < 4) {
            setErrorMessage(["La cabecera del archivo es incorrecta"]);
            return;
        }
        let errorMessages = [];
        file[2].map((cab, idx) => {
            file[2][idx] = cab.toString().toUpperCase();
        });
        cabecera.map((cab) => {
            let idx = file[2].indexOf(cab.title.toString());
            cab.idx = idx;
            if (idx === -1) {
                errorMessages.push(`No se encontró el campo "${cab.title}" `);
            }
        });
        if (errorMessages.length > 0) {
            setErrorMessage(errorMessages);
            return;
        }
        for (let x = 3; x < file.length; x++) {
            let fila = file[x];
            if (fila.length !== 0) {
                let datos = [];
                let validRow = true;
                cabecera.map((cab) => {
                    if (!fila[cab.idx]) {
                        if (cab.title === "CÓDIGO ORACLE") {
                            errorMessages.push(`${fila[cab.idx + 1]}  no se proceso por falta de código`);
                        } else {
                            errorMessages.push(`El campo "${cab.title}" de la fila  ${x + 1}  esta vacía`);
                        }
                        validRow = false;
                    }
                    console.log("go datos->", fila[cab.idx]);
                    datos.push(fila[cab.idx]);
                });
                console.log("datos->", datos);
                datos = datos.map((item) => {
                    if (typeof item === "string") {
                        item = item.trim();
                        console.log("item->", item);
                    }
                    return item;
                });
                console.log("datos after map->", datos);
                if (validRow) {
                    datos.splice(1, 0, json.clientPrefix);
                    let client = {
                        clientCode: datos.splice(0, 1)[0],
                        tagCode: datos.join(":"),
                    };
                    json.clients.push(client);
                }
            }
        }
        setLoadingDeliveries(true);
        createDeliveries(json, (error, data) => {
            let errorMessage = [];
            if (error) {
                errorMessage.push(error);
            }
            if (data && data.code != "OK") {
                errorMessage = data.message ? data.message.split(";") : ["Error del Servidor"];
            } else {
                setLoadingDeliveries(false);
                return;
            }
            setLoadingDeliveries(false);
            setErrorMessage(errorMessage);
        });
    };

    const getWarehouses = (companyId) => {
        if (companyId) {
            setLoadingPlace(true);
            getPlaces(companyId, (places) => {
                setWarehouses(
                    places.map((place) => {
                        return {
                            value: place.id,
                            label: place.name + " - " + place.address,
                            key: "places",
                        };
                    })
                );
                setLoadingPlace(false);
            });
        }
    };
    const handleInputChange = (newValue) => {
        setLoadingCompany(true);
        loadCompaniesFilters(newValue).then((companies) => {
            const newCompanies = companies.map((index) => {
                return {
                    value: index.id,
                    label: index.name,
                    key: "companies",
                };
            });
            setLoadingCompany(false);
            setCompanies(newCompanies);
        });
    };
    return (
        <div className="shy-container-wrapper">
            <div className="shy-container shy-pickup-container">
                <div className="shy-form-controls">
                    <div className="shy-form-controls-label">Fecha*</div>
                    <div className="shy-form-controls-content">
                        <div className="shy-form-controls">
                            <input
                                type="date"
                                value={date}
                                className="shy-form-control-input"
                                onChange={(e) => {
                                    cabecera[3].title = +moment(e.target.value).format("D");
                                    setCabecera([...cabecera]);
                                    setDate(moment(e.target.value).format("yyyy-MM-DD"));
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="shy-form-controls">
                    <div className="shy-form-controls-label">Compañia*</div>
                    <div className="shy-form-controls-content">
                        <div className="shy-form-controls">
                            <Select
                                className="shy-form-control-select"
                                valueKey="id"
                                labelKey="label"
                                options={companies}
                                value={selectedCompany}
                                onChange={(e) => {
                                    selectCompany(e);
                                    getWarehouses(e.value);
                                    setWarehouse(undefined);
                                }}
                                isLoading={loadingCompany}
                                onInputChange={handleInputChange}
                                placeholder={"Compañia"}
                            />
                        </div>
                        {error.company && (
                            <span className="shy-form-control-error">
                                <div className="shy-form-control-error-icon"></div>
                                <span className="shy-form-control-error-text">Este es un campo requerido</span>
                            </span>
                        )}
                    </div>
                </div>
                <div className="shy-form-controls">
                    <div className="shy-form-controls-label">Lugar*</div>
                    <div className="shy-form-controls-content">
                        <Select
                            className="shy-form-control-select"
                            valueKey="id"
                            labelKey="label"
                            options={warehouses}
                            value={warehouse || ""}
                            onChange={(place) => {
                                setWarehouse(place);
                            }}
                            isLoading={loadingPlace}
                            placeholder={"Lugar"}
                        />
                        {error.place && (
                            <span className="shy-form-control-error">
                                <div className="shy-form-control-error-icon"></div>
                                <span className="shy-form-control-error-text">Este es un campo requerido</span>
                            </span>
                        )}
                    </div>
                </div>
                <Dropzone onDrop={(acceptedFiles) => fileLoad(acceptedFiles[0])} maxFiles={2} noClick>
                    {({ getRootProps, getInputProps, acceptedFiles, isDraggingFile, open }) => (
                        <div className="shy-form-controls">
                            <div className="shy-form-controls-label">Archivo: {fileName} </div>
                            <div className="shy-form-controls-content">
                                <div className="shy-form-controls">
                                    <div id="pre-import" className="shy-form-controls">
                                        <div className={"pre-import-drag-and-drop-area pre-import-droppable"}>
                                            <section>
                                                <div {...getRootProps()} className="shy-container-dropdown">
                                                    <input {...getInputProps()} />
                                                    <img src={imgDropFile} alt="" />
                                                    <div className="shy-flx-c shy-flx-clmn shy-mt-16">
                                                        <span className="shy-txt-18">Arrastra y suelta un archivo para importar</span>
                                                        <span className="shy-txt-18">Soportamos .xls y .xlsx</span>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>

                                        <button className="shy-btn fallback shy-shadow-h2 dz-clickable shy-btn-default" onClick={open}>
                                            BUSCAR ARCHIVO DESDE ORDENADOR
                                        </button>
                                    </div>
                                </div>
                                {error.file && (
                                    <span className="shy-form-control-error">
                                        <div className="shy-form-control-error-icon"></div>
                                        <span className="shy-form-control-error-text">Este es un campo requerido</span>
                                    </span>
                                )}
                            </div>
                        </div>
                    )}
                </Dropzone>
                <div className="shy-container-btn-create">
                    <button onClick={parsearData} className="shy-btn shy-btn-create shy-mt-16 shy-shadow-h2">
                        Crear Viajes
                    </button>
                </div>
            </div>
        </div>
    );
}
export default Import;
