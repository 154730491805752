
import './Jobs.scss'
import React from 'react';

const Jobs = ({ job }) => {
    return <div className="shy-tracking-modal-error-report-ctn">
        <div id="pending-jobs" className="shy-loading-box shy-loading-container">
            <div className="row">
                <div className="col s12 ">
                    <div id="pending-jobs-container" className="card">
                        <div className="card-content">
                            <img className="job-rotate" src="https://cdn.shippify.co/images/img-processing.svg" />
                            <span className="card-title card-text-title">Procesando</span>
                            <span className="card-text-detail">Algunos procesos se estan ejecutando. Por favor espere para volver a usar esta sección.</span>
                        </div>
                        <div id="job-wrapper" className="job-wrapper">
                            <div className="card-action card-text" id="card-7467">
                                <div className="text">
                                    <p className="job-title">Job Id: {job.jobId} ( {job.totalDeliveries} Deliveries )</p>
                                    <p className="job-type">Tipo: {job.type}</p>
                                </div>
                                <div className="progress">
                                    <div
                                        style={{ width: job.progress + "%" }}
                                        className="determinate">
                                    </div>
                                </div>
                                <div>
                                    <p className="job-progress">{job.progress}% Completado</p>
                                </div>
                                <div className="job-button-cancel">
                                    <button className="shy-btn shy-btn-second" onclick="deleteJobId(7467)">
                                        cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Jobs;