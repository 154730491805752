export function getPlaces(companyId, cb) {
    let url = `api/company/${companyId}/places`;
    try {
        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                const { places } = data;
                return cb(places);
            });
    } catch (error) {
        console.log(error);
        return cb([]);
    }
}
export function saveTask(tags, cb) {
    let url = `api/tags/create`;
    try {
        let payload = {
            method: "POST",
            body: JSON.stringify(tags),
        };
        fetch(url, payload)
            .then((response) => response.json())
            .then((data) => {
                return cb(null, data);
            });
    } catch (error) {
        console.log(error);
        return cb([]);
    }
}
export function createDeliveries(data, cb) {
    let url = `api/jobs/create`;
    let payload = {
        method: "POST",
        body: JSON.stringify(data),
    };
    try {
        fetch(url, payload)
            .then((response) => response.json())
            .then((res) => {
                return cb(null, res);
            })
            .catch((error) => {
                return cb(error);
            });
    } catch (error) {
        console.log(error);
        return cb(error);
    }
}
export async function loadCompaniesFilters() {
    let url = `api/company/6498/children`;
    let response;
    try {
        response = await fetch(url);
    } catch (error) {
        console.log(error);
        throw new Error("load_failure");
    }

    if (response.status >= 400 && response.status < 500) {
        throw new Error("client_error");
    }

    if (response.status >= 500 && response.status < 600) {
        throw new Error("server_error");
    }

    // Content is json
    const contentType = response.headers.get("Content-Type");
    const mimeType = contentType.split(";")[0].trim();
    if (mimeType !== "application/json") {
        throw new Error("invalid_content_type");
    }

    const filters = await response.json();

    return filters;
}

export function getJobs(cb) {
    let url = `api/jobs`;
    try {
        fetch(url)
            .then((response) => response.json())
            .then((res) => {
                if (res && res.jobs) {
                    return cb(null, res.jobs);
                }
            })
            .catch((error) => {
                return cb(error);
            });
    } catch (error) {
        console.log(error);
        return cb(error);
    }
}

export function getFirebaseData(cb) {
    let url = `api/jobs/data`;
    try {
        fetch(url)
            .then((response) => response.json())
            .then((res) => {
                if (res && res.data) {
                    return cb(null, res.data);
                }
            })
            .catch((error) => {
                console.log(error);
                return cb(error);
            });
    } catch (error) {
        console.log(error);
        return cb(error);
    }
}
