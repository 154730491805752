import React, { useState, useEffect } from "react";
import Import from "./import/import.js";
import Header from "./header/Header";
import DialogError from "./dialogs/error/Error";
import DialogTags from "./dialogs/DialogTags/DialogTags.js";
import Jobs from "./jobs/Jobs.js";
import initJobService from "./../services/jobService";

const Movilbus = () => {
    const [errorMessage, setErrorMessage] = useState();
    const [messageDeliveries, setSuccessMessage] = useState();
    const [dialogTags, setDialogTags] = useState();
    const [loadingDeliveries, setLoadingDeliveries] = useState();
    const [job, setJob] = useState();
    useEffect(() => {
        initJobService({ setJob, setErrorMessage, setSuccessMessage});
    }, []);
    console.log("VERSION 1.8");
    return (
        <>
            <Header
                setErrorMessage={setErrorMessage}
                setSuccessMessage={setSuccessMessage}
                setDialogTags={setDialogTags}
                setLoadingDeliveries={setLoadingDeliveries}
            />
            <Import
                setErrorMessage={setErrorMessage}
                setLoadingDeliveries={setLoadingDeliveries}
            />
            {loadingDeliveries && (
                <div class="shy-loading-box shy-loading-container">
                    <img class="spin" src="https://cdn.shippify.co/images/img-loading.svg" />
                </div>
            )}
            {errorMessage && <DialogError title={"Error"} messages={errorMessage} setErrorMessage={setErrorMessage} />}
            {messageDeliveries && <DialogError title={"Turnos"} messages={messageDeliveries} setErrorMessage={setSuccessMessage} />}
            {dialogTags && (
                <DialogTags
                    className="tag"
                    onClose={setDialogTags}
                    setLoadingPage={setLoadingDeliveries}
                    setSuccessMessage={setSuccessMessage}
                    setErrorMessage={setErrorMessage}
                />
            )}
            {job && <Jobs job={job} />}
        </>
    );
};

export default Movilbus;
