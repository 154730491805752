import React, { useState, useEffect } from "react";
import "./DialogTags.scss";
import { loadCompaniesFilters, saveTask } from "../../../services/localApi";
import Select from "react-select";
import moment from "moment";
import Dialog, { DialogFooter, BtnPrimary } from "../Dialog";

const DialogTags = (props) => {
    useEffect(() => {
        setLoadingCompany(true);
        loadCompaniesFilters().then((companies) => {
            const newCompanies = companies.map((index) => {
                return {
                    value: index.id,
                    label: index.name,
                    key: "companies",
                };
            });
            setLoadingCompany(false);
            setCompanies(newCompanies);
        });
    }, [companies]);
    const { onClose, setLoadingPage, setSuccessMessage, setErrorMessage, className } = props;
    const [error, setError] = useState({});
    const [loadingCompany, setLoadingCompany] = useState();
    const [selectedCompany, selectCompany] = useState();
    const [companies, setCompanies] = useState();
    const time = moment().format("HH:mm");
    const [tag, setTag] = useState({
        entrada: {
            pickTime: time,
            deliveryTime: time,
        },
        salida: {
            pickTime: time,
            deliveryTime: time,
        },
    });

    const validate = () => {
        let valid = true;
        if (!selectedCompany) {
            error.company = true;
            setError({ ...error });
            valid = false;
        } else {
            error.company = false;
            setError({ ...error });
        }
        if (!tag.area) {
            error.area = true;
            setError({ ...error });
            valid = false;
        } else {
            error.area = false;
            setError({ ...error });
        }
        if (!tag.subArea) {
            error.subArea = true;
            setError({ ...error });
            valid = false;
        } else {
            error.subArea = false;
            setError({ ...error });
        }
        if (!tag.rol) {
            error.rol = true;
            setError({ ...error });
            valid = false;
        } else {
            error.rol = false;
            setError({ ...error });
        }
        let nextdayE = tag.entrada.pickTime.includes("23:");
        let nextdayS = tag.salida.pickTime.includes("23:");

        if (
            moment(tag.entrada.pickTime, "HH:mm")
                .add(10, "minutes")
                .isSameOrAfter(moment(tag.entrada.deliveryTime, "HH:mm").add(nextdayE, "days"))
        ) {
            error.entrada = "La hora de recojo debe ser por lo menos quince minutos antes que la hora de arribo";
            setError({ ...error });
            valid = false;
        } else {
            error.entrada = undefined;
            setError({ ...error });
        }
        if (
            moment(tag.salida.pickTime, "HH:mm")
                .add(10, "minutes")
                .isSameOrAfter(moment(tag.salida.deliveryTime, "HH:mm").add(nextdayS, "days"))
        ) {
            error.salida = "La hora de recojo debe ser por lo menos quince minutos antes que la hora de arribo";
            setError({ ...error });
            valid = false;
        } else if (moment(tag.entrada.deliveryTime, "HH:mm").isSameOrAfter(moment(tag.salida.pickTime, "HH:mm")) && !tag.salida.nextDay) {
            error.salida = "Hora de arribo del primer viaje posterior a hora de recogida del segundo viaje";
            setError({ ...error });
            valid = false;
        } else {
            error.salida = undefined;
            setError({ ...error });
        }

        return valid;
    };
    const createTags = () => {
        if (!validate()) return;
        let payload = {
            companyId: selectedCompany.value,
            tagName: `${selectedCompany.label}:${tag.area}:${tag.subArea}:${tag.rol}`.toUpperCase(),
            description: "CUSTOMER_TAG",
            color: "#000000",
            tasks: {
                tasks: [
                    {
                        pickTime: parseInt(moment(tag.entrada.pickTime, "HH:mm").format("HHmm")),
                        pickDay: 0,
                        deliveryTime: parseInt(moment(tag.entrada.deliveryTime, "HH:mm").format("HHmm")),
                        deliveryDay: 0,
                        origin: "client",
                    },
                    {
                        pickTime: parseInt(moment(tag.salida.pickTime, "HH:mm").format("HHmm")),
                        pickDay: tag.salida.nextDay ? 1 : 0,
                        deliveryTime: parseInt(moment(tag.salida.deliveryTime, "HH:mm").format("HHmm")),
                        deliveryDay: tag.salida.nextDay ? 1 : 0,
                        origin: "warehouse",
                    },
                ],
            },
        };
        setLoadingPage(true);
        saveTask(payload, (error, res) => {
            setLoadingPage(false);
            if (error) {
                setErrorMessage(["" + error]);
            }
            setSuccessMessage(["" + res.message]);
        });
    };
    return (
        <Dialog className={className} small title="Turnos" onClose={() => onClose()}>
            <div className="shy-container-wrapper">
                <div>
                    <div className="shy-form-controls">
                        <div className="shy-form-controls-label">Compañía*</div>
                        <div className="shy-form-controls-content">
                            <div className="shy-form-controls">
                                <Select
                                    className="shy-form-control-select"
                                    valueKey="id"
                                    labelKey="label"
                                    options={companies}
                                    value={selectedCompany}
                                    onChange={selectCompany}
                                    // onInputChange={handleInputChange}
                                    placeholder={"Compañia"}
                                    isLoading={loadingCompany}
                                />
                            </div>
                            {error.company && (
                                <span className="shy-form-control-error">
                                    <div className="shy-form-control-error-icon"></div>
                                    <span className="shy-form-control-error-text">This is a required field</span>
                                </span>
                            )}
                        </div>
                    </div>

                    <div className="shy-form-controls">
                        <div className="shy-form-controls-label">Área*</div>
                        <div className="shy-form-controls-content">
                            <div className="shy-form-controls">
                                <input
                                    type="text"
                                    className="shy-form-control-input"
                                    placeholder={"Area"}
                                    onChange={(e) => {
                                        setTag({ ...tag, area: e.target.value });
                                    }}
                                />
                            </div>
                            {error.area && (
                                <span className="shy-form-control-error">
                                    <div className="shy-form-control-error-icon"></div>
                                    <span className="shy-form-control-error-text">This is a required field</span>
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="shy-form-controls">
                        <div className="shy-form-controls-label">Sub Área*</div>
                        <div className="shy-form-controls-content">
                            <div className="shy-form-controls">
                                <input
                                    type="text"
                                    className="shy-form-control-input"
                                    placeholder={"Sub Area"}
                                    onChange={(e) => {
                                        setTag({ ...tag, subArea: e.target.value });
                                    }}
                                />
                            </div>
                            {error.subArea && (
                                <span className="shy-form-control-error">
                                    <div className="shy-form-control-error-icon"></div>
                                    <span className="shy-form-control-error-text">This is a required field</span>
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="shy-form-controls">
                        <div className="shy-form-controls-label">Rol*</div>
                        <div className="shy-form-controls-content">
                            <div className="shy-form-controls">
                                <input
                                    type="text"
                                    className="shy-form-control-input"
                                    placeholder={"Rol"}
                                    onChange={(e) => {
                                        setTag({ ...tag, rol: e.target.value });
                                    }}
                                />
                            </div>
                            {error.rol && (
                                <span className="shy-form-control-error">
                                    <div className="shy-form-control-error-icon"></div>
                                    <span className="shy-form-control-error-text">This is a required field</span>
                                </span>
                            )}
                        </div>
                    </div>

                    <div className="shy-form-controls-label section-title">Recojo*</div>

                    <div className="shy-form-controls shy-form-controls-row">
                        <div className="shy-form-controls-time">
                            <div className="shy-form-controls-label">Hora de recojo:</div>
                            <div className="shy-form-controls-content">
                                <div className="shy-form-controls">
                                    <input
                                        type="time"
                                        className="shy-form-control-input"
                                        placeholder={"Name Client"}
                                        value={tag.entrada?.pickTime}
                                        onChange={(e) => {
                                            setTag({ ...tag, entrada: { ...tag.entrada, pickTime: e.target.value } });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="shy-form-controls-time">
                            <div className="shy-form-controls-label">Hora de arribo:</div>
                            <div className="shy-form-controls-content">
                                <div className="shy-form-controls">
                                    <input
                                        defaultValue={moment("13:30:56", "HH:mm:ss")}
                                        type="time"
                                        className="shy-form-control-input"
                                        placeholder={"Name Client"}
                                        value={tag.entrada?.deliveryTime}
                                        onChange={(e) => {
                                            setTag({ ...tag, entrada: { ...tag.entrada, deliveryTime: e.target.value } });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {error.entrada && (
                        <span className="shy-form-control-error">
                            <div className="shy-form-control-error-icon"></div>
                            <span className="shy-form-control-error-text">{error.entrada}</span>
                        </span>
                    )}
                    <div className="shy-form-controls-label section-title">Reparto*</div>

                    <div className="shy-form-controls shy-form-controls-row">
                        <div className="shy-form-controls-time">
                            <div className="shy-form-controls-label">Hora de recojo:</div>
                            <div className="shy-form-controls-content">
                                <div className="shy-form-controls">
                                    <input
                                        type="time"
                                        className="shy-form-control-input"
                                        placeholder={"Name Client"}
                                        value={tag.salida?.pickTime}
                                        onChange={(e) => {
                                            setTag({ ...tag, salida: { ...tag.salida, pickTime: e.target.value } });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="shy-form-controls-time">
                            <div className="shy-form-controls-label">Hora de arribo:</div>
                            <div className="shy-form-controls-content">
                                <div className="shy-form-controls">
                                    <input
                                        type="time"
                                        className="shy-form-control-input"
                                        placeholder={"Name Client"}
                                        value={tag.salida?.deliveryTime}
                                        onChange={(e) => {
                                            setTag({ ...tag, salida: { ...tag.salida, deliveryTime: e.target.value } });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shy-form-controls-row">
                        <div className="shy-form-controls-content">
                            <div className="shy-form-controls">
                                <input
                                    type="checkbox"
                                    checked={tag?.salida?.nextDay}
                                    onChange={(e) => {
                                        setTag({ ...tag, salida: { ...tag.salida, nextDay: !tag?.salida?.nextDay } });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="shy-form-controls-label padding">Dia Siguiente</div>
                    </div>
                    {error.salida && (
                        <span className="shy-form-control-error">
                            <div className="shy-form-control-error-icon"></div>
                            <span className="shy-form-control-error-text">{error.salida}</span>
                        </span>
                    )}
                </div>
            </div>
            <DialogFooter>
                <BtnPrimary onClick={createTags} text={"Crear Turnos"} />
            </DialogFooter>
        </Dialog>
    );
};
export default DialogTags;
