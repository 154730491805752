
import React from 'react';
import Draggable from 'react-draggable';
import icClose from './../../assets/images/ic-close.svg';
import icSpinner from './../../assets/images/ic-spinner.svg';
import imgSuccess from './../../assets/images/img-success.svg';
import './Dialog.css';
export default (props) => {
  const {
    small, title, onClose, children, isLoading, successMessage, successButton,
    hideCloseBtn, style, className
  } = props;
  let dialog = React.createRef();
  const onCloseClick = () => {
    dialog.current.classList.add('s-dialog-hide-background');
    onClose();
  };
  return (
    <div
      ref={dialog}
      className={'s-dialog '+ className}
      onClick={onCloseClick}>
      <div className='s-dialog-draggable-wrapper'>
        <Draggable cancel='.s-dialog-header,.s-dialog-body'>
          <div className='s-dialog-content-wrapper'>
            <div
              className={`s-dialog-content ${props.className || ''}`}
              onClick={e => e.stopPropagation()}
              style={
                small ?
                  { width: '320px' } :
                  style ?
                  style :
                  {}
              }>
              {
                !hideCloseBtn &&
                <div className='s-dialog-content-toolbar'>
                  <img
                    onClick={onCloseClick}
                    className='shy-pointer'
                    src={icClose}
                    alt=''/>
                </div>
              }
              {
                title &&
                <div className='s-dialog-header'>
                  { title }
                </div>
              }
              <div className='s-dialog-body'>
                {
                  children
                }
                {
                  isLoading &&
                  <div className='s-dialog-body-loading'>
                    <img
                      className='shy-spin'
                      src={icSpinner}
                      alt=''/>
                  </div>
                }
                {
                  successMessage &&
                  <div className='s-dialog-body-success'>
                    <img
                      className={small ? 'shy-w-100' : 'shy-w-300'}
                      src={imgSuccess}
                      alt=''/>
                    <span className='shy-txt-24 shy-mt-16'>
                      {
                        successMessage
                      }
                    </span>
                    { successButton }
                  </div>
                }
              </div>
            </div>
          </div>
        </Draggable>
      </div>
    </div>
  );
};

export const DialogFooter = ({ children }) => {
  return (
    <div className="s-dialog-body-buttons">
      { children }
    </div>
  );
};

export const BtnDefault = ({ onClick, text, disabled }) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className="shy-btn">
      { text }
    </button>
  );
};

export const BtnPrimary = ({ onClick, text, disabled }) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className="shy-btn shy-btn-primary shy-shadow-h2">
      { text }
    </button>
  );
};
