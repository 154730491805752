import React from 'react';
import Dialog, {
    DialogFooter,
    BtnPrimary
  }from "./../Dialog";

const DialogError = (props) => {
    const{messages, setErrorMessage, title}= props
    return (
      <Dialog
        small
        title={title}
        onClose={() =>  setErrorMessage()}>
          {
            messages.map((message) => {
              return <div className='shy-txt-14'>
              { message }
              </div>
            })
          }
       
        <DialogFooter>
          <BtnPrimary
            text={'OK'}
            onClick={() => setErrorMessage()}/>
        </DialogFooter>
      </Dialog>
    );
  }
  export default DialogError;