import Firebase from 'firebase/app';
import 'firebase/firestore';
import progress from './progress';

const initJobService = ({setJob, setErrorMessage, setSuccessMessage}) => {
let first = true;
if (!Firebase.apps.length) {
  try{
    Firebase.initializeApp(JSON.parse(localStorage.firebase));
  }catch{}
}

  const db = Firebase.firestore();
  db.settings({ ignoreUndefinedProperties: true })

  const docRef = db
    .collection('movilbus')
    .where('userId', '==', 'MovilBus')
    .where('checked', '==', false)
    .limit(100);

  docRef.get().then(() => {
    docRef.onSnapshot(snapshot => {
      const newJob = snapshot?.docs?.find((job) => job.data().progress!==100)?.data();
      if(!newJob && !first){
        setJob();
        return setSuccessMessage(["Viajes creados correctamente"]);
      }
      first = false;
      if (newJob?.status == 'failed' && newJob?.error) {
        first = true;
        if (newJob.error.includes("PICKUP_BEHIND")) {
          setErrorMessage(["La hora de recojo de un turno se encuentra antes de la hora actual."]);
        } else if (newJob.error.includes("PICKUP_TOO_CLOSE")) {
          setErrorMessage(["La hora de recojo de un turno se encuentra muy cerca de la hora actual."]);
        } else if (newJob.error.includes("Warehouse provided could not be figured out")) {
          setErrorMessage(["Error al obtener el usuario de la compañia. Intente nuevamente"]);
        } else {
          setErrorMessage([newJob.error]);
        }
        return progress({
          jobId: newJob.jobId,
          checked: true,
        }).finally(() => {
          return setJob();
        })
      }
      return setJob(newJob);
    });
  });
}

export default initJobService;
