import Firebase from 'firebase/app';
import 'firebase/firestore';

const progress = async (data) => {

    const {
        progress,
        status,
        jobId,
        error, // errorObject
        allErrors,
        errorsSummary
    } = data;

    if (!jobId) {
        console.log('There is not a jobId to send update to firestore');
        return;
    };
    const db = Firebase.firestore();
    db.settings({ ignoreUndefinedProperties: true })

    console.log('movilbus', 'JOB_'+jobId)
    const res = await db
        .collection('movilbus')
        .doc('JOB_'+jobId)
        .set(data, { merge: true });
    console.log(res);
    return res;
}

export default progress;