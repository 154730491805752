import React from "react";
import "./Header.scss";

const Header = ({ setDialogTags }) => {
    function logout() {
        localStorage.removeItem("session");
        window.location.reload();
    }
    return (
        <header className="shy-titlebar-wrapper shy-shadow-2 shy-z-idx-2">
            <div className="shy-titlebar">
                <div className="shy-flx-vc shy-mh-16">
                    <span className="shy-titlebar-title shy-mr-16">Movil Bus</span>
                </div>
                <div className="shy-titlebar-right">
                    <div className="tooltip">
                        <img
                            className="icon-add-task"
                            onClick={() => {
                                setDialogTags(true);
                            }}
                            src="https://cdn.shippify.co/icons/icon-add-white.svg"></img>
                        <span className="tooltiptext">Agregar Turnos</span>
                    </div>
                    <div className="tooltip">
                        <img
                            className="icon-add-task"
                            onClick={() => {
                                logout();
                            }}
                            src="https://cdn.shippify.co/icons/ic-logout-white.svg"></img>
                        <span className="tooltiptext">Cerrar sesión</span>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
